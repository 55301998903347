// extracted by mini-css-extract-plugin
export var bannerOverlay = "homePage-module--bannerOverlay--4u+QR";
export var bannerOverlay_inner = "homePage-module--bannerOverlay_inner--6caEx";
export var blog_showcase_carousel = "homePage-module--blog_showcase_carousel--oMpxk";
export var blog_showcase_carousel_image = "homePage-module--blog_showcase_carousel_image--bmf8k";
export var blog_showcase_carousel_image_content = "homePage-module--blog_showcase_carousel_image_content--evF67";
export var blog_showcase_carousel_wrapper = "homePage-module--blog_showcase_carousel_wrapper--i5Jnw";
export var blog_showcase_inner_carousel = "homePage-module--blog_showcase_inner_carousel--pBsXL";
export var blog_showcase_link = "homePage-module--blog_showcase_link--lu9AC";
export var card = "homePage-module--card--EAh5x";
export var card_inner = "homePage-module--card_inner--lkudo";
export var home_banner_arrow = "homePage-module--home_banner_arrow--XaRI0";
export var home_banner_inner = "homePage-module--home_banner_inner--KpXGg";
export var home_banner_inner_scroll = "homePage-module--home_banner_inner_scroll--rKCxJ";
export var home_banner_wrapper = "homePage-module--home_banner_wrapper--C2aNV";
export var home_container = "homePage-module--home_container--K+r1X";
export var home_offer_content_inner = "homePage-module--home_offer_content_inner--ItJoS";
export var home_project_showcase_carousel = "homePage-module--home_project_showcase_carousel--+TV6o";
export var home_project_showcase_carousel_image = "homePage-module--home_project_showcase_carousel_image--4AB8D";
export var home_project_showcase_carousel_image_content = "homePage-module--home_project_showcase_carousel_image_content--Bo0WG";
export var home_project_showcase_carousel_wrapper = "homePage-module--home_project_showcase_carousel_wrapper--zkIfN";
export var home_project_showcase_inner_carousel = "homePage-module--home_project_showcase_inner_carousel--XHkcx";
export var home_project_showcase_link = "homePage-module--home_project_showcase_link--VSZ9G";
export var home_section_offer_inner = "homePage-module--home_section_offer_inner--6cgL+";
export var home_section_offer_inner_content = "homePage-module--home_section_offer_inner_content--dICTg";
export var home_section_offer_inner_content_button = "homePage-module--home_section_offer_inner_content_button--+Uf+8";
export var home_section_offer_inner_contents = "homePage-module--home_section_offer_inner_contents--9j1HB";
export var home_section_offer_inner_img = "homePage-module--home_section_offer_inner_img--y3ugA";
export var home_section_offer_inner_img1 = "homePage-module--home_section_offer_inner_img1--STcmy";
export var home_section_offer_inner_img2 = "homePage-module--home_section_offer_inner_img2--EjP47";
export var home_section_offer_inner_img3 = "homePage-module--home_section_offer_inner_img3--S4ic-";
export var home_slider_wrapper = "homePage-module--home_slider_wrapper--AGr9J";
export var home_testimonial_button = "homePage-module--home_testimonial_button--InFo9";
export var home_testimonial_wrapper = "homePage-module--home_testimonial_wrapper--C44bU";
export var inner_carousel = "homePage-module--inner_carousel--9sd4G";
export var inner_link = "homePage-module--inner_link--VUAOE";
export var logo = "homePage-module--logo--ptLX0";
export var logo_wrapper = "homePage-module--logo_wrapper--RWv2o";
export var mobile_blogContent_content_wrapper = "homePage-module--mobile_blogContent_content_wrapper--dzDQD";
export var mobile_blogContent_wrapper = "homePage-module--mobile_blogContent_wrapper--ASRsx";
export var mobile_blog_content_inner = "homePage-module--mobile_blog_content_inner--UADz3";
export var mobile_blog_content_inner_content = "homePage-module--mobile_blog_content_inner_content--709LX";
export var mobile_section_content3_inner = "homePage-module--mobile_section_content3_inner--pk5-7";
export var mobile_section_content3_inner_contents = "homePage-module--mobile_section_content3_inner_contents--UR61f";
export var mobile_section_content3_inner_contents_wrapper = "homePage-module--mobile_section_content3_inner_contents_wrapper--b3dIF";
export var mobile_section_content3_inner_header = "homePage-module--mobile_section_content3_inner_header--SY6jC";
export var project_details_carousel = "homePage-module--project_details_carousel--FWCLj";
export var project_details_carousel_image = "homePage-module--project_details_carousel_image--QZfPd";
export var project_details_carousel_wrapper = "homePage-module--project_details_carousel_wrapper--mdg5m";
export var project_details_inner_carousel = "homePage-module--project_details_inner_carousel--Wbhtu";
export var quote = "homePage-module--quote--1sO6u";
export var quote_author_inner = "homePage-module--quote_author_inner--hGUbE";
export var scroll = "homePage-module--scroll--C-E0k";
export var section4_img1 = "homePage-module--section4_img1--QsM+M";
export var section4_img2 = "homePage-module--section4_img2--bgZzR";
export var section4_img3 = "homePage-module--section4_img3--MwpIN";
export var section4_img_bg = "homePage-module--section4_img_bg--QogNF";
export var section_content1 = "homePage-module--section_content1--uzVZ0";
export var section_content2 = "homePage-module--section_content2--Ot6Yq";
export var section_content2_inner = "homePage-module--section_content2_inner--1RDGN";
export var section_content2_inner_container = "homePage-module--section_content2_inner_container--JIK3v";
export var section_content2_inner_content = "homePage-module--section_content2_inner_content--g7Rdl";
export var section_content2_inner_mobile__carousel = "homePage-module--section_content2_inner_mobile__carousel--fpLGL";
export var section_content2_inner_mobile_carousel_content = "homePage-module--section_content2_inner_mobile_carousel_content--kQSiO";
export var section_content2_inner_mobile_carousel_content_box = "homePage-module--section_content2_inner_mobile_carousel_content_box--tQYAB";
export var section_content2_inner_mobile_wrapper = "homePage-module--section_content2_inner_mobile_wrapper--qZNsz";
export var section_content2_inner_wrapper = "homePage-module--section_content2_inner_wrapper--rGIBr";
export var section_content3 = "homePage-module--section_content3--Z401I";
export var section_content3_inner = "homePage-module--section_content3_inner--M1320";
export var section_content3_inner_header = "homePage-module--section_content3_inner_header--2cj-U";
export var section_content4 = "homePage-module--section_content4--+ZGGC";
export var section_content4_inner_container = "homePage-module--section_content4_inner_container--YE6OM";
export var section_content5 = "homePage-module--section_content5--KWuG4";
export var section_content5_inner_header = "homePage-module--section_content5_inner_header--IGWKC";
export var section_inner_content = "homePage-module--section_inner_content--vo4ON";
export var testimonial_card_wrapper = "homePage-module--testimonial_card_wrapper--y6v+z";
export var testimonial_carousel = "homePage-module--testimonial_carousel--i4Dmj";
export var testimonial_inner = "homePage-module--testimonial_inner--JJLKC";
export var testimonial_inner_card = "homePage-module--testimonial_inner_card--PVONm";
export var testimonial_text = "homePage-module--testimonial_text--qFcIS";
export var testimonial_user_companyName = "homePage-module--testimonial_user_companyName--6g8rx";
export var testimonial_user_name = "homePage-module--testimonial_user_name--hAMLq";
export var testimonial_user_profile = "homePage-module--testimonial_user_profile--anP5C";